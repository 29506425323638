import axios from "axios";
import apiConst from "../api/apiConst";
import RecuperaSenhaInterface from "../interfaces/RecuperaSenhaInterface";

const RecuperaSenhaService = async ( recuperar: RecuperaSenhaInterface) => {

    const response = await axios.put ("/api/v1/auth/reset_psw", recuperar, { baseURL: apiConst.urlServer , headers : { 
        'Accept': 'application/json', 
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + recuperar.token,
    }});

    if(response.status != 201 && response.status != 200){
        return { isError: true, message: "Erro ao atualizar senha!" }
    }
    
    if(!response.data){
        return { isError: true, message: "Erro ao atualizar senha!" }
    }

    if(!response.data.status){
        return { isError: true, message: response.data.data }
    }
  
    return { isError: false, message: "Sucesso ao atualizar senha!"}    
}

export default RecuperaSenhaService